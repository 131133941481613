@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #4d8aff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.h_professional {
  height: 321px;
}

.textarea ::placeholder {
  background-image: url(./assets/images/svg/placeholder_edit_icon.svg);
  background-position: center right;
  background-repeat: no-repeat;
  color: #bec1c3;
}

.textarea textarea:focus {
  outline: none;
}

.ql-container.ql-snow {
  border: 0 !important;
}

.write_ups_card {
  width: calc(50% - 22px);
}


.nav_links.active {
  color: #4d8aff;
}

.nav_links.active svg path {
  fill: #4d8aff;
}

.storke_link.active svg path {
  fill: none !important;
  stroke: #4d8aff;
}

.main_searchbar:focus {
  outline: none !important;
  border: none;
}

::placeholder {
  color: #bec1c3 !important;
}
.Toastify__toast-container{
  z-index: 10000000001 !important;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../src/assets/icons/dropdown-arrow.svg");
  background-repeat: no-repeat;
  background-position: right 12px center;
}

.bgprofile_img {
  background-position: bottom;
  background-size: cover;
}
.App {
  text-align: center;
  padding: 20px;
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
input[type="file"] {
  margin: 20px;
}
.three_dot_follow svg {
  width: 4px;
  height: 13px;
}



.navbar_link_size a {
  border-bottom: 2px solid transparent;
}

input {
  background-color: transparent !important;
  outline: none !important;
}
.preview {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.input {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 100px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.hashtag {
  color: blue;
}

input:focus {
  outline: none !important;
}

select {
  background-color: transparent !important;
}

.like svg path {
  fill: #4d8aff;
}

.ql-editor {
  font-size: 16px !important;
}

.description_content p {
  display: inline;
}


.question_paper_name:hover {
  cursor: pointer;
}

.nav2_width1 {
  max-width: calc(100vw - 126px);
}
.nav2_width2 {
  max-width: calc(100vw - 156px);
}

.rpv-core__inner-pages::-webkit-scrollbar {
  width: 15px !important;
}
.rpv-core__inner-pages::-webkit-scrollbar-thumb {
  width: unset !important;
  border-radius: 0 !important;
  background-color: rgb(156, 154, 154) !important;
}
.rpv-core__inner-pages::-webkit-scrollbar-track {
  width: unset !important;
  border-radius: 0 !important;
  background-color: rgb(218, 216, 216) !important;
}
.hide_scroll::-webkit-scrollbar {
  width: 0 !important;
}
.hide_scroll::-webkit-scrollbar-thumb {
  width: unset !important;
  border-radius: 0 !important;
  background-color: unset !important;
}
.hide_scroll::-webkit-scrollbar-track {
  width: unset !important;
  border-radius: 0 !important;
  background-color: unset !important;
}

.tox-statusbar {
  display: none !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
  box-shadow: none !important;
}

.tox-tinymce {
  border-radius: 0 !important;
  border: 0 !important;
}

.tox .tox-edit-area::before {
  border: 0 !important;
  border-radius: 0 !important;
}

.mce-content-body p {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.description_content a {
  color: blue !important;
}
.open-in-new-tab {
  color: blue !important;
}

.rpv-thumbnail__items--selected .rpv-thumbnail__item {
  background-color: #a6a8aa !important;
  padding: 5px !important;
  margin-top: 10px;
}

.rpv-thumbnail__container {
  overflow: hidden !important;
}

.rpv-thumbnail__item--single:hover,
.rpv-thumbnail__item--dual-cover:hover,
.rpv-thumbnail__items--dual:hover .rpv-thumbnail__item,
.rpv-thumbnail__items--dual-cover:hover .rpv-thumbnail__item {
  background-color: #a6a8aa !important;
  padding: 5px !important;
}

.rpv-thumbnail__item--single,
.rpv-thumbnail__item--dual-cover {
  padding: 2px !important;
}

.rpv-thumbnail__items {
  position: relative !important;
}

.rpv-toolbar__right {
  margin-left: 0 !important;
  height: 24px !important;
  align-items: unset !important;
}

.rpv-page-navigation__current-page-input {
  margin-right: 5px !important;
  text-align: right !important;
  width: 55px !important;
}
.rpv-page-navigation__current-page-input input {
  text-align: right !important;
  padding: 0 5px !important;
}

.rpv-core__textbox {
  background-color: transparent !important;
  border: 0 !important;
  box-sizing: border-box;
  border-radius: 0 !important;
  padding: 0 !important;
  height: unset !important;
}

button[aria-label="Thumbnail"] {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 11;
  background-color: transparent !important;
}
.rpv-core__minimal-button {
  height: 100% !important;
  display: flex;
  align-items: center;
}

.rpv-default-layout__toolbar {
  background-color: #474747 !important;
  background-image: linear-gradient(hsla(0, 0%, 32%, 0.99), hsla(0, 0%, 27%, 0.95)) !important;
  box-shadow: inset 0 1px 1px hsla(0, 0%, 0%, 0.15), inset 0 -1px 0 hsla(0, 0%, 100%, 0.05),
    0 1px 0 hsla(0, 0%, 0%, 0.15), 0 1px 1px hsla(0, 0%, 0%, 0.1) !important;
}
.rpv-default-layout__container {
  border: 0 !important;
  background-color: #404040 !important;
  background-image: url("./assets/images/png/texture.png");
}
.rpv-default-layout__sidebar-headers {
  display: none !important;
}
.rpv-core__inner-page {
  background-color: transparent !important;
}
.rpv-default-layout__body {
  background-color: transparent !important;
}

.rpv-toolbar__left {
  margin-left: 53px;
}

button[aria-label="Full screen"] svg path {
  fill: white !important;
  stroke: white !important;
  stroke-width: 2px !important;
}
button[aria-label="Full screen"] svg {
  width: 20px !important;
  height: 20px !important;
}
button[aria-label="More actions"] svg path {
  fill: white !important;
  stroke: white !important;
  stroke-width: 2px !important;
}

.rpv-core__icon path {
  stroke: white !important;
  stroke-width: 2px !important;
}

.rpv-default-layout__container span,
.rpv-default-layout__container p,
.rpv-default-layout__container div,
.rpv-default-layout__container input {
  color: white !important;
  font-size: 14px !important;
}
.rpv-core__popover-body {
  background-color: #3d3a3a !important;
}

.rpv-core__popover-body-arrow {
  background-color: #3d3a3a !important;
}

.rpv-zoom__popover-target-arrow {
  border-color: rgb(255 255 255) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;
}

.rpv-default-layout__sidebar--opened {
  width: 200px !important;
  box-shadow: inset -1px 0 0 hsla(0, 0%, 0%, 0.25) !important;
  background-color: hsla(0, 0%, 0%, 0.1) !important;
  border-top: 1px solid #333 !important;
}
.rpv-default-layout__sidebar-content {
  width: 200px !important;
  box-shadow: inset -1px 0 0 hsla(0, 0%, 0%, 0.25) !important;
  background-color: hsla(0, 0%, 0%, 0.1) !important;
  border-top: 1px solid #333 !important;
}
.rpv-thumbnail__list::-webkit-scrollbar {
  width: unset !important;
}
.rpv-thumbnail__list::-webkit-scrollbar-thumb {
  width: unset !important;
  border-radius: 0 !important;
  background-color: rgb(156, 154, 154) !important;
}
.rpv-thumbnail__list::-webkit-scrollbar-track {
  width: unset !important;
  border-radius: 0 !important;
  background-color: rgb(218, 216, 216) !important;
}

input[aria-label="Enter a page number"] {
  margin-right: 5px !important;
  border-radius: 2px !important;
  background-color: hsla(0, 0%, 100%, 0.09) !important;
  background-image: linear-gradient(hsla(0, 0%, 100%, 0.05), hsla(0, 0%, 100%, 0)) !important;
  background-clip: padding-box !important;
  border: 1px solid hsla(0, 0%, 0%, 0.35) !important;
  border-color: hsla(0, 0%, 0%, 0.32) hsla(0, 0%, 0%, 0.38) hsla(0, 0%, 0%, 0.42) !important;
  box-shadow: 0 1px 0 hsla(0, 0%, 0%, 0.05) inset, 0 1px 0 hsla(0, 0%, 100%, 0.05) !important;
}
.rpv-core__modal-body {
  background-color: #595959 !important;
}

button[data-testid="print__menu"],
button[data-testid="get-file__download-menu"] {
  display: none !important;
}

.descri p {
  word-break: break-all !important;
}

.custom_spiner {
  animation: spinner 800ms infinite linear;
  position: relative;
  border-top: 3px solid #d0021a86;
  border-right: 3px solid #d0021b;
  border-bottom: 3px solid #ffffffff;
  background-color: white;
}
.pdf_svg {
  animation: zoom 1s infinite linear;
}
.post_editor .quill {
  height: 100%;
}

.ql-editor {
  outline: none !important;
}
.ql-editor img {
  width: 200px;
}

@keyframes zoom {
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}

.navbar_link_size svg {
  height: 20px;
  width: 20px;
}

@media (max-width: 1090px) {
  .create_prepare {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    justify-content: start !important;
  }

  .create_prepare img {
    width: 150px;
  }

  .flex_row_reverse {
    gap: 40px;
    flex-direction: row-reverse;
  }
}



  .z_51 {
    z-index: 51 !important;
  }
  .body_overlay {
    background-color: rgba(0, 0, 0, 0.402);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    z-index: 51;
  }


  .ql-container.ql-snow {
    border: 0 !important;
  }

  .ql-toolbar.ql-snow {
    border: 0 !important;
  }



  .navbar_link_size svg {
    height: 15.2px;
    width: 15.2px;
  }

  .rpv-default-layout__sidebar-content {
    background-color: hsla(0, 0%, 0%, 0.7) !important;
  }


.people_card {
  width: calc(33.33% - 56px);
}

.show_pdf {
  width: calc(25% - 42px);
}

.scroll_hidden::-webkit-scrollbar {
  display: none;
}

@media (max-width: 1279px) {
  .people_card {
    width: calc(33.33% - 44px);
  }
  .show_pdf {
    width: calc(33.33% - 38px);
  }
}
@media (max-width: 1023.9px) {
  .show_pdf {
    width: calc(33.33% - 24px);
  }
  .d_none {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .network_hub_icon svg {
    width: 29px;
    height: 29px;
  }

  .h_professional {
    height: 100%;
  }

  .network_hub_icon {
    width: 29px !important;
    height: 29px !important;
  }

  .we_also_icons svg {
    width: 21px;
    height: 21px;
  }

  .footer_icon svg {
    width: 13px;
    height: 13px;
  }

  .add_qus_btn svg {
    width: 20px;
    height: 20px;
  }

  .write_ups_card {
    width: 100%;
  }

  .fiter_btn svg {
    width: 13px;
    height: 13px;
  }

  .pagination_icon {
    width: 43px;
    height: 40px;
    background-color: #eeefef;
  }

  .pagination_icon svg {
    width: 7px;
    height: 11px;
  }

  .like_icon svg {
    width: 27px;
    height: 31px;
  }

  .share_icon svg {
    width: 30px;
    height: 30px;
  }

  .max_icon svg {
    width: 39px;
    height: 39px;
  }

  .h_max {
    max-height: calc(100vh - 272px) !important;
  }

  .profile_vertical_icon svg {
    width: 4px;
    height: 8px !important;
  }
}
@media (max-width: 767px) {
  .people_card {
    width: calc(33.33% - 30px);
  }
  .show_pdf {
    width: calc(33.33% - 24px);
  }
}
@media (max-width: 740px) {
  .network_hub_icon svg {
    width: 29px;
    height: 29px;
  }

  .network_hub_icon {
    width: 29px !important;
    height: 29px !important;
  }

  .we_also_icons svg {
    width: 21px;
    height: 21px;
  }

  .social_meadia_icon svg {
    width: 13px;
    height: 13px;
  }

  .h_professional {
    height: calc(100vh - 320px);
  }
}

@media (max-width: 640px) {
  .network_hub_icon svg {
    width: 21px;
    height: 21px;
  }
  .add_qus_btn svg {
    width: 15px;
    height: 15px;
  }

  .network_hub_icon {
    width: 21px !important;
    height: 21px !important;
  }

  .we_also_icons svg {
    width: 31px;
    height: 31px;
  }

  .we_also_card {
    margin: 0 0 !important;
  }


  .search_popup_shadow {
    box-shadow: 0px 8px 20px 0px #2f327d1a;
  }

  .like_icon svg {
    width: 18px;
    height: 20px;
  }

  .share_icon svg {
    width: 18px;
    height: 18px;
  }

  .max_icon svg {
    width: 20px;
    height: 20px;
  }

  .search_icon svg {
    width: 19px;
    height: 19px;
  }

  .tick_icon {
    width: 15px;
  }

  .about_icon svg path {
    fill: #4d8aff;
  }

  .about_icon svg {
    width: 16px;
    height: 16px;
  }

  .slider_btn svg {
    width: 6px;
    height: 9px;
  }
}

@media (max-width: 639px) {
  .show_pdf {
    width: calc(50% - 18px);
  }
  .people_card {
    width: calc(50% - 8px);
  }
  .Toastify__toast-body {
    font-size: 14px;
  }
  .follower_height {
    height: calc(100% - 80px);
  }
  .interest_h {
    max-height: 24vh;
  }

  .tox-platform-touch {
    height: calc(100vh - 130px) !important;
  }
  .post_editor .tox-platform-touch {
    height: calc(100vh - 150px) !important;
  }
  .document_editor .tox-platform-touch {
    height: calc(100vh - 433px) !important;
  }
  .rpv-core__inner-pages::-webkit-scrollbar {
    width: 5px !important;
  }
  .rpv-thumbnail__list::-webkit-scrollbar {
    width: 5px !important;
  }
  .post_editor {
    flex: 1;
    overflow-y: auto;
  }
  .post_editor .quill {
    max-height: calc(100vh - 150px);
  }
  .texteditor-container .ql-editor {
    height: calc(100vh - 200px) !important;
  }
  .ql-snow .ql-tooltip {
    position: absolute;
    transform: translateY(10px);
    transform: translateX(40%)!important;
  }
}
.ql-editor {
  white-space: pre-wrap;
}

.rpv-core__text-layer-text{
  display: none !important;
}

@media (min-width: 640px) {
  .sidebar_links.active {
    background-color: #4d8aff;
    color: white !important;
  }

  .popup_shadow {
    box-shadow: 0px 2px 15px 6px #2f327d1a;
  }
  .post_editor {
    flex: 1;
    overflow-y: auto;
  }

  .texteditor-container .ql-editor {
    height: 284px !important;
  }
  .ql-snow .ql-tooltip {
    position: absolute;
    transform: translateY(10px);
    transform: translateX(50%)!important;
  }
}

@media (min-width: 768px) {  .h_max {
    max-height: 280px;
  }

  .add_qus_btn svg {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 1024px) {
  .nav_links.active {
    color: #4d8aff;
    border-bottom: 2px solid #4d8aff;
  }

  .nav_links.active svg path {
    fill: #4d8aff;
  }

  .storke_link.active svg path {
    fill: none !important;
    stroke: #4d8aff;
  }
}

@media (min-width: 1280px) {
  .create_prepare_industry p {
    font-size: 18px !important;
  }

  .create_prepare_industry h2 {
    font-size: 30px !important;
  }

  .create_prepare_industry img {
    width: 150px !important;
  }

  .create_prepare_industry .create_prepare {
    width: 33.33% !important;
    margin: 0 !important;
    min-width: unset !important;
  }

  .create_prepare_industry .flex_no_wrap {
    flex-wrap: nowrap !important;
  }
  .post_editor {
    overflow-y: auto;
    flex: 1;
  }
}

@media (min-width: 1533px) {
  .create_prepare_industry p {
    font-size: 20px !important;
  }


  .create_prepare_industry h2 {
    font-size: 36px !important;
  }

  .create_prepare_industry img {
    width: unset !important;
  }

  .create_prepare_industry .create_prepare {
    width: 33.33% !important;
    margin: 0 !important;
    min-width: unset !important;
  }

  .create_prepare_industry .flex_no_wrap {
    flex-wrap: nowrap !important;
  }

  .post_editor {
    flex: 1;
    overflow-y: auto;
  }

  .texteditor-container .ql-editor {
    height: 473px !important;
  }
}

.uploadedImgScroll::-webkit-scrollbar {
  display: none !important;
}



.texteditor-container .ql-editor {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  height: 100%; 
  overflow-y: auto; 
  position: relative;
}

.texteditor-container .ql-editor img {
  align-self: flex-end;
  margin-top: auto; 
  max-width: 100%; 
  display: block; 
}

.ql-snow .ql-editor img {
  max-width: 100%;
}

.RichTextEditor__root___2QXK- {
  background: #fff;
  border: 1px solid transparent !important;
  border-radius: 3px;
  font-family: Georgia, serif;
  font-size: 14px;
}
 
.EditorToolbar__root___3_Aqz {
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  margin: 0 10px;
  padding: 10px 0 5px;
  border-bottom: 1px solid transparent!important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
